const FR = [
    {
        administrativeAreaLevel1: 'Île-de-France',
        country: 'France',
        countryCode: 'FR',
        loc: {lat: 48.8546, lon: 2.3477},
        city: 'Paris',
        formattedAddress: 'Paris, France',
    },
    {
        administrativeAreaLevel1: "Provence-Alpes-Côte d'Azur",
        country: 'France',
        countryCode: 'FR',
        administrativeAreaLevel2: 'Bouches-du-Rhône',
        loc: {lat: 43.2954, lon: 5.3631},
        city: 'Marseille',
        formattedAddress: 'Marseille, France',
    },
    {
        administrativeAreaLevel1: 'Auvergne-Rhône-Alpes',
        country: 'France',
        countryCode: 'FR',
        administrativeAreaLevel2: 'Rhône',
        loc: {lat: 45.7539, lon: 4.84699},
        city: 'Lyon',
        formattedAddress: 'Lyon, France',
    },
    {
        administrativeAreaLevel1: 'Occitanie',
        country: 'France',
        countryCode: 'FR',
        administrativeAreaLevel2: 'Haute-Garonne',
        loc: {lat: 43.6043, lon: 1.44199},
        city: 'Toulouse',
        formattedAddress: 'Toulouse, France',
    },
];

const ES = [
    {
        administrativeAreaLevel1: 'Comunidad de Madrid',
        country: 'España',
        countryCode: 'ES',
        administrativeAreaLevel2: 'Madrid',
        loc: {lat: 40.4167, lon: -3.70358},
        city: 'Madrid',
        formattedAddress: 'Madrid, España',
    },
    {
        administrativeAreaLevel1: 'Catalonia',
        country: 'España',
        countryCode: 'ES',
        administrativeAreaLevel2: 'Provincia de Barcelona',
        loc: {lat: 41.3829, lon: 2.17743},
        city: 'Barcelona',
        formattedAddress: 'Barcelona, España',
    },
    {
        administrativeAreaLevel1: 'Andalucía',
        country: 'España',
        countryCode: 'ES',
        loc: {lat: 37.3886, lon: -5.99534},
        city: 'Sevilla',
        formattedAddress: 'Sevilla, España',
    },
    {
        administrativeAreaLevel1: 'Comunitad Valenciana',
        country: 'España',
        countryCode: 'ES',
        administrativeAreaLevel2: 'Valencia',
        loc: {lat: 39.4699, lon: -0.375951},
        city: 'Valencia',
        formattedAddress: 'Valencia, España',
    },
];

const DE = [
    {
        country: 'Deutschland',
        countryCode: 'DE',
        administrativeAreaLevel2: 'Berlin',
        loc: {lat: 52.517, lon: 13.3889},
        city: 'Berlin',
        formattedAddress: 'Berlin, Deutschland',
    },
    {
        country: 'Deutschland',
        countryCode: 'DE',
        administrativeAreaLevel2: 'Bayern',
        loc: {lat: 48.1371, lon: 11.5754},
        city: 'München',
        formattedAddress: 'München, Deutschland',
    },
    {
        country: 'Deutschland',
        countryCode: 'DE',
        administrativeAreaLevel2: 'Hamburg',
        loc: {lat: 53.5503, lon: 10.0007},
        city: 'Hamburg',
        formattedAddress: 'Hamburg, Deutschland',
    },
    {
        country: 'Deutschland',
        countryCode: 'DE',
        administrativeAreaLevel2: 'Nordrhein-Westfalen',
        loc: {lat: 50.9384, lon: 6.95997},
        city: 'Köln',
        formattedAddress: 'Köln, Deutschland',
    },
];
// eslint-disable-next-line camelcase
const fr_BE = [
    {
        administrativeAreaLevel1: 'Région flamande',
        administrativeAreaLevel2: 'Brabant flamand',
        country: 'Belgique',
        countryCode: 'BE',
        loc: {lat: 50.792508, lon: 4.288073},
        city: 'Bruxelles',
        formattedAddress: 'Bruxelles, Belgique',
    },
    {
        administrativeAreaLevel1: 'Région flamande',
        administrativeAreaLevel2: 'Anvers',
        country: 'Belgique',
        countryCode: 'BE',
        loc: {lat: 51.2211, lon: 4.39971},
        city: 'Anvers',
        formattedAddress: 'Anvers, Belgique',
    },
    {
        administrativeAreaLevel1: 'Région flamande',
        administrativeAreaLevel2: 'Flandre orientale',
        country: 'Belgique',
        countryCode: 'BE',
        loc: {lat: 51.0538, lon: 3.72501},
        city: 'Gand',
        formattedAddress: 'Gand, Belgique',
    },
    {
        administrativeAreaLevel1: 'Région wallonne',
        administrativeAreaLevel2: 'Hainaut',
        country: 'Belgique',
        countryCode: 'BE',
        loc: {lat: 50.412, lon: 4.44362},
        city: 'Charleroi',
        formattedAddress: 'Charleroi, Belgique',
    },
];

const BE = [
    {
        administrativeAreaLevel1: 'Vlaams Gewest',
        administrativeAreaLevel2: 'Vlaams-Brabant',
        country: 'België',
        countryCode: 'BE',
        loc: {lat: 50.792508, lon: 4.288073},
        city: 'Brussel',
        formattedAddress: 'Brussel, België',
    },
    {
        administrativeAreaLevel1: 'Vlaams Gewest',
        administrativeAreaLevel2: 'Antwerpen',
        country: 'België',
        countryCode: 'BE',
        loc: {lat: 51.2211, lon: 4.39971},
        city: 'Antwerpen',
        formattedAddress: 'Antwerpen, België',
    },
    {
        administrativeAreaLevel1: 'Vlaams Gewest',
        administrativeAreaLevel2: 'Oost-Vlaanderen',
        country: 'België',
        countryCode: 'BE',
        loc: {lat: 51.0538, lon: 3.72501},
        city: 'Gent',
        formattedAddress: 'Gent, België',
    },
    {
        administrativeAreaLevel1: 'Wallonië',
        administrativeAreaLevel2: 'Henegouwen',
        country: 'België',
        countryCode: 'BE',
        loc: {lat: 50.412, lon: 4.44362},
        city: 'Charleroi',
        formattedAddress: 'Charleroi, België',
    },
];

const NL = [
    {
        administrativeAreaLevel2: 'Noord-Holland',
        country: 'Nederland',
        countryCode: 'NL',
        loc: {lat: 52.3728, lon: 4.8936},
        city: 'Amsterdam',
        formattedAddress: 'Amsterdam, Nederland',
    },
    {
        administrativeAreaLevel2: 'Zuid-Holland',
        country: 'Nederland',
        countryCode: 'NL',
        loc: {lat: 51.9229, lon: 4.46317},
        city: 'Rotterdam',
        formattedAddress: 'Rotterdam, Nederland',
    },
    {
        administrativeAreaLevel2: 'Zuid-Holland',
        country: 'Nederland',
        countryCode: 'NL',
        loc: {lat: 52.08, lon: 4.31135},
        city: 'Den Haag',
        formattedAddress: 'Den Haag, Nederland',
    },
    {
        administrativeAreaLevel2: 'Utrecht',
        country: 'Nederland',
        countryCode: 'NL',
        loc: {lat: 52.095, lon: 5.10971},
        city: 'Utrecht',
        formattedAddress: 'Utrecht, Nederland, Nederland',
    },
];
// eslint-disable-next-line camelcase
const en_NL = [
    {
        administrativeAreaLevel2: 'North Holland',
        country: 'The Netherlands',
        countryCode: 'NL',
        loc: {lat: 52.3728, lon: 4.8936},
        city: 'Amsterdam',
        formattedAddress: 'Amsterdam, The Netherlands',
    },
    {
        administrativeAreaLevel2: 'North Holland',
        country: 'The Netherlands',
        countryCode: 'NL',
        loc: {lat: 51.9229, lon: 4.46317},
        city: 'Rotterdam',
        formattedAddress: 'Rotterdam, The Netherlands',
    },
    {
        administrativeAreaLevel2: 'North Holland',
        country: 'The Netherlands',
        countryCode: 'NL',
        loc: {lat: 52.08, lon: 4.31135},
        city: 'The Hague',
        formattedAddress: 'The Hague, The Netherlands',
    },
    {
        administrativeAreaLevel2: 'North Holland',
        countryCode: 'NL',
        loc: {lat: 52.095, lon: 5.10971},
        city: 'Utrecht',
        formattedAddress: 'Utrecht, The Netherlands',
    },
];
// eslint-disable-next-line camelcase
const en_GB = [
    {
        administrativeAreaLevel1: 'England',
        administrativeAreaLevel1Code: 'England',
        administrativeAreaLevel2: 'Greater London',
        country: 'United Kingdom',
        countryCode: 'GB',
        loc: {lat: 51.509648, lon: -0.099076},
        city: 'London',
        formattedAddress: 'London, England, United Kingdom',
    },
    {
        administrativeAreaLevel1: 'England',
        administrativeAreaLevel1Code: 'England',
        administrativeAreaLevel2: 'Greater Manchester',
        country: 'United Kingdom',
        countryCode: 'GB',
        loc: {lat: 53.471419, lon: -2.244834},
        city: 'Manchester',
        formattedAddress: 'Manchester, England, United Kingdom',
    },
    {
        administrativeAreaLevel1: 'England',
        administrativeAreaLevel1Code: 'England',
        administrativeAreaLevel2: 'West Midlands',
        country: 'United Kingdom',
        countryCode: 'GB',
        loc: {lat: 52.478308, lon: -1.893538},
        city: 'Birmingham',
        formattedAddress: 'Birmingham, England, United Kingdom',
    },
    {
        administrativeAreaLevel1: 'England',
        administrativeAreaLevel1Code: 'England',
        administrativeAreaLevel2: 'Bristol',
        country: 'United Kingdom',
        countryCode: 'GB',
        loc: {lat: 51.449749, lon: -2.584173},
        city: 'Bristol',
        formattedAddress: 'Bristol, England, United Kingdom',
    },
];
// eslint-disable-next-line camelcase
const en_DK = [
    {
        country: 'Denmark',
        countryCode: 'DK',
        loc: {lat: 55.6712474, lon: 12.5237848},
        city: 'Copenhagen',
        formattedAddress: 'Copenhagen, Denmark',
    },
    {
        country: 'Sweden',
        countryCode: 'SE',
        loc: {lat: 59.33111, lon: 18.04867},
        city: 'Stockholm',
        formattedAddress: 'Stockholm, Sweden',
    },
    {
        country: 'Finland',
        countryCode: 'FI',
        loc: {lat: 60.21654, lon: 24.97088},
        city: 'Helsinki',
        formattedAddress: 'Helsinki, Finland',
    },
    {
        country: 'Norway',
        countryCode: 'NO',
        loc: {lat: 59.91667, lon: 10.77608},
        city: 'Oslo',
        formattedAddress: 'Oslo, Norway',
    },
];
// eslint-disable-next-line camelcase
const en_AE = [
    {
        country: 'United Arab Emirates',
        countryCode: 'AE',
        loc: {lat: 25.07637, lon: 55.22172},
        city: 'Dubai',
        formattedAddress: 'Dubai, United Arab Emirates',
    },
    {
        country: 'United Arab Emirates',
        countryCode: 'AE',
        loc: {lat: 24.42376, lon: 54.51456},
        city: 'Abu Dhabi',
        formattedAddress: 'Abu Dhabi, United Arab Emirates',
    },
    {
        country: 'Qatar',
        countryCode: 'QA',
        loc: {lat: 25.29562, lon: 51.51329},
        city: 'Doha',
        formattedAddress: 'Doha, Qatar',
    },
    {
        country: 'Saudi Arabia',
        countryCode: 'SA',
        loc: {lat: 21.49558, lon: 39.2975},
        city: 'Jeddah',
        formattedAddress: 'Jeddah, Saudi Arabia',
    },
];

// eslint-disable-next-line camelcase
const en_CH = [
    {
        administrativeAreaLevel1: 'Zürich district',
        country: 'Switzerland',
        countryCode: 'CH',
        loc: {lat: 47.36667, lon: 8.55},
        city: 'Zurich',
        formattedAddress: 'Zurich, Switzerland',
    },
    {
        administrativeAreaLevel1: 'Basel-Stadt',
        country: 'Switzerland',
        countryCode: 'CH',
        loc: {lat: 47.53633, lon: 7.60593},
        city: 'Basel',
        formattedAddress: 'Basel, Switzerland',
    },
    {
        country: 'Switzerland',
        countryCode: 'CH',
        loc: {lat: 46.20804, lon: 6.12737},
        city: 'Geneva',
        formattedAddress: 'Geneva, Switzerland',
    },
    {
        country: 'Switzerland',
        countryCode: 'CH',
        loc: {lat: 46.87251, lon: 4.44362},
        city: 'Bern',
        formattedAddress: 'Bern, Switzerland',
    },
    {
        country: 'Switzerland',
        countryCode: 'CH',
        loc: {lat: 46.52178, lon: 6.633},
        city: 'Lausanne',
        formattedAddress: 'Lausanne, Switzerland',
    },
];

// eslint-disable-next-line camelcase
const fr_CH = [
    {
        administrativeAreaLevel1: 'Zürich district',
        country: 'Suisse',
        countryCode: 'CH',
        loc: {lat: 47.36667, lon: 8.55},
        city: 'Zurich',
        formattedAddress: 'Zurich, Suisse',
    },
    {
        administrativeAreaLevel1: 'Basel-Stadt',
        country: 'Suisse',
        countryCode: 'CH',
        loc: {lat: 47.53633, lon: 7.60593},
        city: 'Bâle',
        formattedAddress: 'Bâle, Suisse',
    },
    {
        country: 'Suisse',
        countryCode: 'CH',
        loc: {lat: 46.20804, lon: 6.12737},
        city: 'Genève',
        formattedAddress: 'Genève, Suisse',
    },
    {
        country: 'Suisse',
        countryCode: 'CH',
        loc: {lat: 46.87251, lon: 4.44362},
        city: 'Berne',
        formattedAddress: 'Berne, Suisse',
    },
    {
        country: 'Suisse',
        countryCode: 'CH',
        loc: {lat: 46.52178, lon: 6.633},
        city: 'Lausanne',
        formattedAddress: 'Lausanne, Suisse',
    },
];

// eslint-disable-next-line camelcase
const de_CH = [
    {
        administrativeAreaLevel1: 'Zürich district',
        country: 'Schweiz',
        countryCode: 'CH',
        loc: {lat: 47.36667, lon: 8.55},
        city: 'Zürich',
        formattedAddress: 'Zürich, Schweiz',
    },
    {
        administrativeAreaLevel1: 'Basel-Stadt',
        country: 'Schweiz',
        countryCode: 'CH',
        loc: {lat: 47.53633, lon: 7.60593},
        city: 'Basel',
        formattedAddress: 'Basel, Schweiz',
    },
    {
        country: 'Schweiz',
        countryCode: 'CH',
        loc: {lat: 46.20804, lon: 6.12737},
        city: 'Genf',
        formattedAddress: 'Genf, Schweiz',
    },
    {
        country: 'Schweiz',
        countryCode: 'CH',
        loc: {lat: 46.87251, lon: 4.44362},
        city: 'Bern',
        formattedAddress: 'Bern, Schweiz',
    },
    {
        country: 'Schweiz',
        countryCode: 'CH',
        loc: {lat: 46.52178, lon: 6.633},
        city: 'Lausanne',
        formattedAddress: 'Lausanne, Schweiz',
    },
];
// eslint-disable-next-line camelcase
export {FR, ES, DE, BE, fr_BE, NL, en_NL, en_GB, en_DK, en_AE, en_CH, fr_CH, de_CH};
