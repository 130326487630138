import * as defaultCitiesSuggestions from './default-cities-suggestions.js';
export {defaultCitiesSuggestions};

export function getDefaultCitiesSuggestionsForLocale(locale) {
    if (!locale) {
        return [];
    }
    const formattedLocale = locale.replace('-', '_');
    const suggestions = defaultCitiesSuggestions[formattedLocale];
    if (suggestions) {
        return suggestions;
    }
    const countryCode = formattedLocale.split('_')[1];
    return defaultCitiesSuggestions[countryCode] || [];
}
